/**axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
 import axios from 'axios';
 import QS from 'qs';
 import { Toast } from 'antd-mobile'
 import  Cookies from 'js-cookie'
 import { useNavigate } from 'react-router-dom'
 // 请求超时时间
 axios.defaults.timeout = 200000;
 // 地址
 axios.defaults.baseURL = "https://mapi.jkaobang.cn"
 // 请求拦截器
 axios.interceptors.request.use(
     config => {
         return config;
     },
     error => {
         return Promise.error(error);
     }
 )
 
 // 响应拦截器
 axios.interceptors.response.use(
     response => {
       
         if (response.status === 200) {
             return Promise.resolve(response);
         } else {
             return Promise.reject(response);
         }
     },
     // 服务器状态码不是200的情况
     error => {
         if (error.message.includes('timeout')) {
            Toast.show({
                icon: 'fail',
                content: '网络超时',
              })
             return Promise.reject(error);
         }
         if(error.code === "ERR_NETWORK") {
            Toast.show({
                icon: 'fail',
                content: error.message,
              })
        
            return Promise.reject(error.message);
         }
         if (error.response.status) {
             switch (error.response.status) {
                case 400:
                    Toast.show({
                        icon: 'fail',
                        content: '登录失效，请重新登录！',
                      })
                      Cookies.remove('token')
                      setTimeout(()=>{
                        window.location.replace('/')
                      },1500)
                    break;
                 case 404:
                    Toast.show({
                        icon: 'fail',
                        content: '404,网络请求不存在',
                      })
                     break;
                 case 500:
                    Toast.show({
                        icon: 'fail',
                        content: '500,服务器端错误',
                      })
                     break;
                     //其他错误，直接抛出错误提示
                 default:
                    Toast.show({
                        icon: 'fail',
                        content: error.response.data.msg || "Error",
                      })
             }
             return Promise.reject(error.response);
         }
     }
 );
 
 let timer = new Date().getTime().toString();
 /**
  * get方法，对应get请求
  * @param {String} url [请求的url地址]
  * @param {Object} params [请求时携带的参数]
  */
 export function GET(url, params) {
    if(Cookies.get('token')) {
        params.token = Cookies.get('token')
     }
     return new Promise((resolve, reject) => {
         axios.get(url + `?t=${timer}`, { //随机数 针对IE设置
                 params: params
             })
             .then(res => {
                 resolve(res.data);
             })
             .catch(err => {
                 reject(err)
             })
     });
 }
 /**
  * post方法，对应post请求
  * @param {String} url [请求的url地址]
  * @param {Object} params [请求时携带的参数]
  */
 export function POST(url, params) {
    if(Cookies.get('token')) {
        params.token = Cookies.get('token')
     }
     return new Promise((resolve, reject) => {
         axios.post(url, QS.stringify(params))
             .then(res => {
                 resolve(res.data);
             })
             .catch(err => {
                 reject(err)
             })
     });
 }
 
 
 export function POST_JSON(url, params) {
    if(Cookies.get('token')) {
        params.token = Cookies.get('token')
     }
     return new Promise((resolve, reject) => {
         axios.post(url, params)
             .then(res => {
                 resolve(res.data);
             })
             .catch(err => {
                 reject(err)
             })
     });
 }
 
 /**
  * post方法，对应post请求, form-data
  * @param {String} url [请求的url地址]
  * @param {Object} params [请求时携带的参数]
  */
 export function FILE(url, params) {
    if(Cookies.get('token')) {
        params.token = Cookies.get('token')
     }
     let formData = new FormData()
     for (var key in params) {
         formData.append(key, params[key])
     }
     return new Promise((resolve, reject) => {
         axios.post(url, formData)
             .then(res => {
                 resolve(res.data);
             })
             .catch(err => {
                 reject(err)
             })
     });
 }