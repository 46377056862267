import './index.scss'
import { useNavigate } from 'react-router-dom'
import {messageList,deleteMessage} from 'api'
import React from 'react'
const {ipcRenderer} = window.require?window.require("electron"):'';
const News = () =>{
    // const go = useNavigate()
    const [list,setList] = React.useState([])
    const [page,setPage] = React.useState(1)
    const myRef = React.useRef()
    const [loding,setloding] = React.useState(1)
    const [flag,setflag] = React.useState(true)
    React.useEffect(()=>{
        handleMessageList()
    },[])
    function handleMessageList() {
        messageList({page:page}).then(res=>{
            if(res.code===200) {
                setflag(true)
                if(res.data&&res.data.length!==0) {
                    var arr = list 
                    arr = [...arr,...res.data,]
                    let page1 = page
                    ++page1
                    setPage(page1)
                    setList(arr)
                    if(res.data.length<10) {
                        setloding(2)
                    }
                }else{
                    setloding(2)
                }
            }
        })
    }
    const Scroll = () => {
        let height1 = myRef.current.clientHeight  // 可以得到我们设置的高度 (不包括滚动的高度)
        let height2 = myRef.current.scrollTop  // 当滚动时被卷去的高度
        let height3 = myRef.current.scrollHeight  // 全部的高度 包括滚动的高度
        if(height1 + height2 + 5 > height3 && flag){   // 当距离底部20 则会触发
            // 加了flag 是为了防止反复触发
            setflag(false)
            handleMessageList()
            setloding(1)
        }
    }
    function go(t) {
        ipcRenderer.send('openUrl',{url:t})
    }
    // function handleDeleteMessage(id,i) {
    //     Modal.confirm({
    //         title: '提示！',
    //         content: '是否删除当前消息！',
    //         okText:"确定",
    //         cancelText:'取消',
    //         onOk:()=>{
    //             deleteMessage({message_id:id}).then(res=>{
    //                 if(res.code==200) {
    //                     setList(list.splice(i,1))
    //                     message.success('删除成功')
    //                 }else{
    //                     message.error(res.msg)
    //                 }
    //             })
    //         }
    //     })
        
    // }
    return(
        <div className="news">
             <div className="title">我的消息</div>
             <div className='news_list' ref={myRef} onScroll={Scroll}>
                 {
                    list.map((v,i)=>{
                        return(
                            <div className='news_list_item cursor' onClick={()=>go('/news/detail?id='+v.id)}>
                                <img src={require('images/info.png')} alt="" />
                                <div className='info'>
                                    <div className={v.is_read==0?'top active':'top'}>
                                        <h2>{v.title}</h2>
                                        <p>{v.create_time}</p>
                                    </div>
                                    <div className='bot'>{v.content}</div>
                                </div>
                            </div>
                        )
                    })
                 }
                  <div className='loding' >{loding===1?'正在加载...':loding===2?'没有更多数据了':''}</div>
             </div>
        </div>
    )
}
export default News