import React,{ useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { getLiveRecordDetail } from 'api'
import { Tabs } from 'antd';
import './index.scss'
export default function HistoryDetail() {
    const [search] = useSearchParams()
    const [detail,setDetail] =  React.useState([])
    const [url,setUrl] = React.useState(search.get('url'))
    const onChange = (key) => {
        setUrl(key)
      };
      const [items,setItems] = React.useState([])
    useEffect(()=>{
        getLiveRecordDetail({
            live_id:search.get('id')
        }).then((res)=>{
            if(res.code==200) {
                var item = []
                if(res.data.record_video_url_json) {
                    res.data.record_video_url_json.map((v,i)=>{
                        item.push({key:v,label:"第"+(i+1)+"段"})
                    })
                }
                setItems(item)
                setDetail(res.data)
            }
        })
    },[])
    return (
        <div style={{width:'100%',height:'100%'}}>
            {items.length>1?<Tabs defaultActiveKey={search.get('url')} items={items} onChange={onChange} />:''}
            <div className="history_detail" style={{height:'calc(100% - 47px)'}}>
                <video src={url} controls autoPlay></video>
            </div>
        </div>
    )
}