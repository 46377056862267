import './index.scss'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import { getLiveRecordList} from'api'
import { Toast } from 'antd-mobile'
const { ipcRenderer } = window.require?window.require("electron"):'';

const Home = () =>{
    const [tabStu,setTabStu] = useState(0)
    const [userinfo] = useState(Cookies.get('userinfo')?JSON.parse(Cookies.get('userinfo')):{})
    const [page,setPage] = useState(1)
    const [list,setList] = useState([])
    const myRef = useRef()
    const [flag,setflag] = useState(true)
    const [loding,setloding] = useState(1)
    const go = useNavigate()
    useEffect(()=>{
        GetList()
    },[tabStu])
    useEffect(()=>{
        ipcRenderer.on('home', (e, data) => {
            console.log(data)
            if(data.key =='logout') {
                Cookies.remove('token')
                Cookies.remove('userinfo')
                go('/login')
            }else if(data.key=='add') {
                setPage(1)
                setList([])
                GetList()
            }
        })
        return () => {
            ipcRenderer.removeAllListeners('home')
        };
    },[])
    function GetList() {
        getLiveRecordList({
            page:page,
            status:tabStu==0?1:tabStu==1?0:2,
        }).then(res=>{
            if(res.code===200) {
                setflag(true)
                if(res.data&&res.data.length!==0) {
                    var arr = list 
                    arr = [...arr,...res.data,]
                    let page1 = page
                    ++page1
                    setPage(page1)
                    setList(arr)
                }else{
                    setloding(2)
                }
            }
        })
    }
    const Scroll = () => {
        let height1 = myRef.current.clientHeight  // 可以得到我们设置的高度 (不包括滚动的高度)
        let height2 = myRef.current.scrollTop  // 当滚动时被卷去的高度
        let height3 = myRef.current.scrollHeight  // 全部的高度 包括滚动的高度
        if(height1 + height2 + 5 > height3 && flag){   // 当距离底部20 则会触发
            // 加了flag 是为了防止反复触发
            setflag(false)
            GetList()
            setloding(1)
        }
    }
   
    function navigate(t,v) {
        if(t.indexOf('/live')!==-1) {
            var start =new Date(v.start_time).getTime()
            var now = new Date().getTime()
            var time = start - now
            console.log()
            if(time/1000/60>5) {
                Toast.show({
                    icon: '',
                    content: '还未到直播开始时间，请您继续等待',
                })
                return
            }
           
        }
        ipcRenderer.send('openUrl',{url:t})
    }
    return(
        <div className='home'>
            <div className='header'>
                <div className='left cursor' onClick={()=>{navigate('/my')}}>
                    <div className='photo'>
                        <img src={userinfo.avatar||require('images/MR_img.png')} alt="" />
                    </div>
                    <p>{userinfo.username}</p>
                </div>
               <div className='right cursor'  onClick={()=>{navigate('/news')}}>
                    <img src={require('images/home_info.png')} alt="" />
                    {userinfo.message_flag?<i></i>:''}
               </div>
            </div>
            <div className='add_history'>
                <div className='add_history_item cursor' onClick={()=>{navigate('/add')}}>
                    <img src={require('images/add.png')} alt="" />
                    <p>新增直播</p>
                </div>
                <div className='add_history_item cursor' onClick={()=>{navigate('/history')}}>
                    <img src={require('images/history.png')} alt="" />
                    <p>历史直播</p>
                </div>
            </div>
            <div className='home_tab'>
                <div className='tab_list'>
                    {
                        ['审核通过','审核中','被驳回'].map((v,i)=>{
                            return(
                                <div key={i} className={tabStu===i?'tab_list_item active':'tab_list_item'} onClick={()=>{setList([]);setPage(1);setTabStu(i)}}>{v}</div>
                            )
                        })
                    }
                    <div className='tab_list_item_bot' style={{transform:'translateX('+tabStu*88+'px)'}}></div>
                </div>
            </div>
            <div className='home_list' ref={myRef} onScroll={Scroll}>
                {
                    list.map((v,i)=>{
                        return(
                        <div className='home_item cursor'>
                            <div className='home_item_top'>
                                <div className='img'>
                                    <img src={v.live_cover} alt="" />
                                </div>
                                <div className='info'>
                                    <p className='p1'>{v.live_name}</p>
                                    <p className='p2'>{v.live_username}</p>
                                    <p className='p3'>{v.live_desc}</p>
                                    <p className='p4'>开始时间：{v.start_time}</p>
                                </div>
                            </div>
                            {tabStu===0?
                                <div className='home_item_bot' onClick={()=>navigate('/live?id='+v.id+'&status='+v.live_status,v)}>
                                    <p>{v.live_status==3?'继续':'开启'}直播</p>
                                </div>
                            :tabStu===1 || tabStu===2?
                                <div className='icon'>
                                    <img src={require(('images/icon'+(tabStu+1)+'.png'))} alt="" />
                                </div>
                            :''
                            }
                        </div>
                        )
                    })
                }
                {list.length>3?<div className='loding' >{loding===1?'正在加载...':loding===2?'没有更多数据了':''}</div>:""}
            </div>
        </div>
    )
}
export default Home