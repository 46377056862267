import Logo from 'components/logo'
import React,{ useState } from 'react'
import { Toast } from 'antd-mobile'
import { useNavigate } from 'react-router-dom'
import { getForget,GetCode } from 'api'
import Cookies from 'js-cookie'
const {ipcRenderer} = window.require?window.require("electron"):'';
//修改密码和找回密码
const Forget = () =>{
    const navigate = useNavigate()
    const [messageBtn,setmessageBtn] = useState('获取验证码')
    const [forgetForm,setForgetForm] = useState({
        mobile:"",
        code:"",
        password:"",
        confirm_password:"",
    })
    const [inputType,setInputType] = useState('password')
    const [inputType1,setInputType1] = useState('password')
    function hanldeForget() {
        if(!forgetForm.mobile) {
            Toast.show({
                icon: 'fail',
                content: '请输入手机号',
              })
           return
       }
       if(!/^1[3-9]\d{9}$/.test(forgetForm.mobile)) {
            Toast.show({
                icon: 'fail',
                content: '请输入正确手机号',
            })
        
           return
       }
       if(!forgetForm.code) {
            Toast.show({
                icon: 'fail',
                content: '请输入验证码',
            })
           return
       }
       if(!forgetForm.password) {
            Toast.show({
                icon: 'fail',
                content: '请输入密码',
            })
           return
       }
       if(!forgetForm.confirm_password) {
            Toast.show({
                icon: 'fail',
                content: '请输入确认密码',
            })
           return
       }
       if(forgetForm.confirm_password !== forgetForm.password) {
            Toast.show({
                icon: 'fail',
                content: '两次密码输入不一致',
            })
           return
       }
       getForget(forgetForm).then(res=>{
           if(res.code===200) {
                Toast.show({
                    icon: 'success',
                    content: '密码修改成功',
                })
                ipcRenderer.send('home', {key:'logout'})
           }else{
                Toast.show({
                    icon: 'fail',
                    content: res.msg,
                })
           }
       })
   }
   function handleGetCode() {
    if(!forgetForm.mobile) {
        Toast.show({
            icon: 'fail',
            content: '请输入手机号',
          })
      
        return
    }
    if(!/^1[3-9]\d{9}$/.test(forgetForm.mobile)) {
        Toast.show({
            icon: 'fail',
            content: '请输入正确手机号',
          })
        return
    }
    GetCode({mobile:forgetForm.mobile,is_forget:1}).then(res=>{
        if(res.code===200) {
            Toast.show({
                icon: 'success',
                content: '获取成功',
              })
            let i = 90;
            let timer = setInterval(() => {
                setmessageBtn("重新获取(" + i + ")") 
                i--;
                if (i < 0) {
                    setmessageBtn("重新获取")
                    clearInterval(timer);
                }
            }, 1000);
        }else{
            Toast.show({
                icon: 'none',
                content:res.msg,
              })
        }
    })
}
    return(
        <div className="login">
             <Logo />
             <div className='form_item mt30'>
                <img className='img1' src={require('images/phone.png')} alt="" />
                <input type="text" maxLength={11}  value={forgetForm.mobile} onInput={(e)=>setForgetForm({...forgetForm,mobile:e.target.value})} placeholder='请输入手机号'/>
            </div>
            <div className='form_item mt13'>
                <img className='img1' src={require('images/login_code.png')} alt="" />
                <input type="text" value={forgetForm.code} onInput={(e)=>setForgetForm({...forgetForm,code:e.target.value})} placeholder='请输入验证码'/>
                <p className='cursor' onClick={()=>messageBtn==='获取验证码' || messageBtn==='重新获取'?handleGetCode():''}>{messageBtn}</p>
            </div>
            <div className='form_item mt13'>
                <img className='img1' src={require('images/pwd.png')} alt="" />
                <input type={inputType} value={forgetForm.password}  onInput={(e)=>setForgetForm({...forgetForm,password:e.target.value})} placeholder='请输入密码'/>
                <img className='img2 cursor' onClick={()=>{setInputType(inputType==='text'?'password':'text')}} src={require('images/'+(inputType==='password'?'input_hide':'input_show')+'.png')} alt="" />
            </div>
            <div className='form_item mt13'>
                <img className='img1' src={require('images/pwd.png')} alt="" />
                <input type={inputType1} value={forgetForm.confirm_password}  onInput={(e)=>setForgetForm({...forgetForm,confirm_password:e.target.value})} placeholder='请再次输入密码'/>
                <img className='img2 cursor' onClick={()=>{setInputType1(inputType1==='text'?'password':'text')}} src={require('images/'+(inputType1==='password'?'input_hide':'input_show')+'.png')} alt="" />
            </div>
            <div className={forgetForm.mobile&&forgetForm.password&&forgetForm.code&&forgetForm.confirm_password?'jkb_btn login_btn mt30 cursor login_btn_bot':'login_btn mt30 cursor login_btn_bot'} onClick={()=>hanldeForget()}>
                完成
            </div>
        </div>
    )
}
export default Forget