import Cookies from 'js-cookie'
import { useState } from 'react'
import './index.scss'
import { updateUserInfo,UploadImg } from 'api'
import { Toast } from 'antd-mobile'
import { useLocation } from 'react-router'
import { Radio } from 'antd';
const {ipcRenderer} = window.require?window.require("electron"):'';
const Userinfo = () =>{
    // const go = useNavigate()
    const { pathname,search } = useLocation()
    const [userinfo,setUserinfo] = useState(Cookies.get('userinfo')?JSON.parse(Cookies.get('userinfo')):{})
    function handleUpdateUserInfo() {
        let data = userinfo 
        if(!data.avatar) {
            Toast.show({
                icon: 'fail',
                content: '请上传头像',
              })
            return
        }
        if(!data.username) {
            Toast.show({
                icon: 'fail',
                content: '请输入姓名',
              })
            return
        }
        if(data.sex==='') {
            Toast.show({
                icon: 'fail',
                content: '请选择性别',
              })
            return
        }
        delete data['id']
        delete data['message_flag']
        delete data['user_type']
        data.nickname = data.username
        updateUserInfo(data).then(res=>{
            if(res.code===200) {
                Toast.show({
                    icon: 'success',
                    content: '更新成功',
                  })
                  Cookies.set('userinfo',JSON.stringify(data))
                  setTimeout(()=>{
                    go(-1)
                  },1500)
            }else{
                Toast.show({
                    icon: 'fail',
                    content: res.msg,
                  })
            }
        })
    }
    function handleUploadImg(e) {
        console.log(e)
        let file = e.target.files[0]
        UploadImg({file:file}).then(res=>{
            if(res.code==200) {
                setUserinfo({...userinfo,avatar:res.data.image})
            }
        })
    }
    function go(t,u) {
        ipcRenderer.send('openUrl',{url:t,u:pathname+search})
    }
    return(
        <div className="add">
            <div className="title">个人信息</div>
            <div className='user_photo'>
                <div className='upload'>
                    <img src={userinfo.avatar||require('images/MR_img.png')} className="img1" alt="" />
                </div>
                <img src={require('images/photo.png')} className="img2" alt="" />
                <input type="file" className='cursor' onChange={(e)=>handleUploadImg(e)}/>
            </div>
            <div className='add_input'>
                <div className='form_input'>
                    <p>姓名 ：</p>
                    <input type="text" value={userinfo.username} onInput={(e)=>setUserinfo({...userinfo,username:e.target.value})} placeholder='请输入'/>
                </div>
                <div className='form_input'>
                    <p>年龄 ：</p>
                    <input type="text" value={userinfo.age} onInput={(e)=>setUserinfo({...userinfo,age:e.target.value})} placeholder='请输入'/>
                </div>
                <div className='form_input'>
                    <p>性别 ：</p>
                    <div className="radio">
                    <Radio.Group onChange={(e)=>setUserinfo({...userinfo,sex:e.target.value})} value={userinfo.sex}>
                        <Radio value={1}>男</Radio>
                        <Radio value={2}>女</Radio>
                    </Radio.Group>
                </div>
                </div>
                {/* <div className='form_input'>
                    <p>学历 ：</p>
                    <input type="text" placeholder='请输入'/>
                </div>
                <div className='form_input'>
                    <p>职务/职称 ：</p>
                    <input type="text" placeholder='请输入'/>
                </div> */}
                <div className='form_input_u'>
                    <p>简介 ：</p>
                    <textarea placeholder='请输入' onChange={(e)=>setUserinfo({...userinfo,explain:e.target.value})} value={userinfo.explain}></textarea>
                </div>
            </div>
            <div className='form_btn ' onClick={()=>handleUpdateUserInfo()}>
                <p className='btn cursor'>确定</p>
            </div>
        </div>

    )
}
export default Userinfo