import React from 'react'
// 引入路由守卫
import { Routes,Route,useLocation,Navigate, useNavigate,useRoutes  } from 'react-router-dom';
import Home from 'pages/home'
import Login from 'pages/login'
import Forget from 'pages/login/forget'
import Contact from 'pages/login/contact'
import Add from 'pages/add'
import History from 'pages/history'
import My from 'pages/my'
import Userinfo from 'pages/userinfo'
import News from 'pages/news'
import NewsDetail from 'pages/news/detail'
import Feedback from 'pages/feedback'
import  Cookies from 'js-cookie'
import { getUserInfo } from 'api'
import Live from 'pages/live'
import SharePop from 'pages/live/sharePop'
import HistoryDetail from 'pages/history/detail'
const RouterConfig = () => {
  const location = useLocation();
  const { pathname } = location;
  React.useEffect(()=>{
      if(Cookies.get('token')&&pathname!=='/login') {
          getUserInfo({}).then(res=>{
            if(res.code===200) {
                Cookies.set('userinfo',JSON.stringify(res.data))
            }
        })
      }
  },[pathname])
  const routes =  [
    {
      path: '/',
      element: <Navigate to={Cookies.get('token')?'/home':'/login'} />,
      auth:false,
    },
    {
        path: '/login',
        element: Cookies.get('token')?<Navigate to={'/home'} />:<Login/>,
        auth:false,
    },
    {
      path: '/forget',
      element: <Forget/>,
      auth:false,
    },
    {
      path: '/contact',
      element: <Contact/>,
      auth:true,
    },
    {
      path: '/home',
      element: Cookies.get('token')?<Home/>:<Navigate to={'/login'} />,
      auth:true,
    },
    {
      path: '/add',
      element: <Add/>,
      auth:true,
    },
    {
      path: '/history',
      element: <History/>,
      auth:true,
    },
    {
      path: '/history/detail',
      element: <HistoryDetail/>,
      auth:true,
    },
    {
      path: '/my',
      element: <My/>,
      auth:true,
    },
    {
      path: '/userinfo',
      element: <Userinfo/>,
      auth:true,
    },
    {
      path: '/news',
      element: <News/>,
      auth:true,
    },
    {
      path: '/news/detail',
      element: <NewsDetail/>,
      auth:true,
    },
    {
      path: '/feedback',
      element: <Feedback/>,
      auth:true,
    },
    {
      path: '*',
      element: <Home /> // 注意，404页面是不需要路由守卫的！
    },
    {
      path: '/live',
      element: <Live/>,
      auth:true,
    },
    {
      path: '/share',
      element: <SharePop/>,
      auth:true,
    }
]
  return (useRoutes(routes))
}

export default RouterConfig ;

