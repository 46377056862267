import './index.scss'

import { useEffect, useState,useRef } from 'react';
import Cookies from 'js-cookie';
 
const Im =(props) =>{
    var [userId,setUserId] =useState(Cookies.get('userinfo')?JSON.parse(Cookies.get('userinfo')).id+'':{})
    const [value,setValue] = useState('')
    const messagesEndRef = useRef(null);
    const scrollToBottom = () => {
        console.log(messagesEndRef.current)
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    };
    useEffect(scrollToBottom, [props.imList]);
    return(<div className='right_im' style={props.stu==1?{marginLeft:0,width:'auto',height:'100vh'}:{}}>
    <div className='top'  style={props.stu==1?{appRegion: "drag"}:{}}>
        <img src={require('images/msg.png')} alt="" />
        <p>聊天</p>
    </div>
    <div className='center'>
       {props.imList.map((v,i)=>{
        return(
            <p>
            <span className={userId==v.from?'me':''}>{v.nick}：</span>
                {v.payload&&v.payload.text}
           </p>
        )
      
        })} 
        <div ref={messagesEndRef} />
    </div>
    <div className='bottom'>
        <div className="text">
            <textarea value={value} onInput={(e)=>setValue(e.target.value)}  placeholder='请输入文字'></textarea>
            <div className='send cursor' onClick={()=>{props.sendImMsg(value);setValue('')}}>发送</div>
        </div>
      
    </div>
</div>)
}
export default Im