import RouterConfig  from "router"
import 'style/index.scss'
import { useLocation } from 'react-router-dom';
const { ipcRenderer } =  window.require?window.require("electron"):'';
function App() {
  const location = useLocation();
  const { pathname,search } = location;
  console.log(pathname)
  function minimizeWin() {
    console.log(pathname)
      ipcRenderer.send("min",pathname=='/login'?'/home':pathname+search); // 通知主进程我要进行窗口最小化操作
  }
  function closeWin() {
    console.log(pathname,search)
      ipcRenderer.send("close",pathname=='/login'?'/home':pathname+search); // 通知主进程我要关闭
  }
  return (
    <div style={{width:'100%',height:'100%'}}>
    {pathname==='/live' || pathname==='/share' || pathname==='/history/detail'?<RouterConfig />:
      <div className="jkb_content">
        <div className="jkb_main">
          <div style={{appRegion: "drag"}} className="close_small1"></div>
          <div className="close_small cursor">
            <div onClick={()=>minimizeWin()} className="jkb_small cursor">
              <img src={require('images/small.png')}   alt="" />
            </div>
            <div onClick={()=>closeWin()} className="jkb_close cursor">
              <img src={require('images/close.png')}   alt="" />
          </div>
          </div>
          <RouterConfig/>
        </div>
      </div>}
    </div>
  );
}

export default App;
