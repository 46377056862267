import './index.scss'
import { useState } from 'react'
import {  Toast } from 'antd-mobile'
import { UploadImg,addLiveRecord } from'api'
import { useLocation } from 'react-router'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'
const {ipcRenderer} = window.require?window.require("electron"):'';
const Add = () =>{
    const { pathname,search } = useLocation()
    const [visible2, setVisible2] = useState(false)
    const [visible3, setVisible3] = useState(false)
    const [form,setForm] = useState({
        live_name:"",
        live_cover:"",
        live_pic:"",
        live_desc:"",
        start_time:"",
        end_time:"",
    })
    const [formdate,setFormdate] = useState({
        start_time:"",
        end_time:"",
    })
    function handleUploadImg(e,key) {
        let file = e.target.files[0]
        UploadImg({file:file}).then(res=>{
            if(res.code===200) {
                setForm({...form,[key]:res.data.image})
            }
        })
    }
    function handleAddLiveRecord() {
        
        if(!form.live_name) {
            Toast.show({
                icon: 'fail',
                content: '请输入直播名称',
              })
            return
        }
        if(!form.live_cover) {
            Toast.show({
                icon: 'fail',
                content: '请上传直播封面',
              })
            return
        }
        if(!form.live_pic) {
            Toast.show({
                icon: 'fail',
                content: '请上传详情封面',
              })
            return
        }
        if(!form.live_desc) {
            Toast.show({
                icon: 'fail',
                content: '请输入直播简介',
              })
            return
        }
        if(!form.start_time) {
            Toast.show({
                icon: 'fail',
                content: '请选择开启时间',
              })
            return
        }
        if(!form.end_time) {
            Toast.show({
                icon: 'fail',
                content: '请选择结束时间',
              })
            return
        }
        if(form.start_time>form.end_time) {
            Toast.show({
                icon: 'fail',
                content: '开始时间必须小于结束时间',
              })
            return
        }
        addLiveRecord(form).then(res=>{
            if(res.code===200) {
                Toast.show({
                    icon: 'success',
                    content: res.msg,
                })
                ipcRenderer.send('home', {key:'add'})
                setTimeout(()=>{
                    ipcRenderer.send('openUrl',{url:-1,u:pathname+search})
                },1500)
            }else{
                Toast.show({
                    icon: 'fail',
                    content: res.msg,
                  })
            }
           
        })
    }
    return(
        <div className="add">
            <div className="title">新增直播</div>
            <div className='add_input'>
                <div className='form_input'>
                    <p>直播名称 ：</p>
                    <input type="text" value={form.live_name} onInput={(e)=>setForm({...form,live_name:e.target.value})} placeholder='请输入'/>
                </div>
                <div className='form_input_u'>
                    <p>直播封面图  ：</p>
                    <div className='upload'>
                            <img className='img1' src={form.live_cover || require('images/upload.png')} alt="" />
                            <input type="file" className='cursor' onChange={(e)=>handleUploadImg(e,'live_cover')} />
                            {form.live_cover?<img className='img_del' onClick={()=>setForm({...form,live_cover:''})} src={require('images/img_del.png')} alt="" />:""}
                    </div>
                </div>
                <div className='form_input_u'>
                    <p>详情封面图  ：</p>
                    <div className='upload'>
                            <img className='img1' src={form.live_pic || require('images/upload.png')} alt="" />
                            <input type="file" className='cursor' onChange={(e)=>handleUploadImg(e,'live_pic')}/>
                            {form.live_pic?<img className='img_del' onClick={()=>setForm({...form,live_pic:''})} src={require('images/img_del.png')} alt="" />:""}
                    </div>
                </div>
                <div className='form_input_u'>
                    <p>直播介绍 ：</p>
                    <textarea placeholder='请输入' value={form.live_desc} onInput={(e)=>setForm({...form,live_desc:e.target.value})}></textarea>
                </div>
                <div className='form_input mt20'>
                    <p>开启时间 ：</p>
                    <div className='input_time' onClick={()=>setVisible2(true)}>
                        <input type="text" placeholder='请选择' value={formdate.start_time}  readOnly/>
                        <img src={require('images/time.png')} alt="" />
                    </div>
                </div>
                <div className='form_input mt20'>
                    <p>结束时间 ：</p>
                    <div className='input_time' onClick={()=>setVisible3(true)}>
                        <input type="text" placeholder='请选择' value={formdate.end_time} readOnly/>
                        <img src={require('images/time.png')} alt="" />
                    </div>
                </div>
                {visible2 || visible3?<div className='DatePicker' onClick={()=>{setVisible2(false);setVisible3(false)}}>
                    {visible2? <DatePicker showTimeSelect  minDate={new Date()} selected={new Date(formdate.start_time || new Date())} showIcon inline  onChange={(date) =>{ 
                            setVisible2(false)
                            setFormdate({...formdate,start_time:moment(date).format('YYYY-MM-DD HH:mm')})
                            setForm({...form,start_time:moment(date).unix()})}}/>:''}
                    {visible3? <DatePicker showTimeSelect  minDate={new Date()} selected={new Date(formdate.end_time || new Date())} showIcon inline  onChange={(date) =>{ 
                            setVisible3(false)
                            setFormdate({...formdate,end_time:moment(date).format('YYYY-MM-DD HH:mm')})
                            setForm({...form,end_time:moment(date).unix()})}}/>:''}
                </div>:''}
                
               
                {/* <DatePicker
                    visible={visible2}
                    min={new Date()}
                    mouseWheel
                    onClose={() => {
                        setVisible2(false)
                    }}
                    precision='minute'
                    onConfirm={val => {
                        console.log(moment(val).unix())
                        setFormdate({...formdate,start_time:moment(val).format('YYYY-MM-DD HH:mm')})
                        setForm({...form,start_time:moment(val).unix()})
        
                    }}
                />
                <DatePicker
                    visible={visible3}
                    min={new Date()}
                    mouseWheel
                    onClose={() => {
                        setVisible3(false)
                    }}
                    precision='minute'
                    onConfirm={val => {
                        setFormdate({...formdate,end_time:moment(val).format('YYYY-MM-DD HH:mm')})
                        setForm({...form,end_time:moment(val).unix()})
                    }}
                /> */}
            </div>
            <div className='form_btn ' onClick={()=>handleAddLiveRecord()}>
                <p className='btn cursor'>确定</p>
            </div>
        </div>
    )
}
export default Add