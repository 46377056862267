import './index.scss'
import { useEffect,useState } from 'react';
import { useSearchParams } from 'react-router-dom'
import Im from'./im'
import Person from './person'
import  Cookies from 'js-cookie'
import { sdkAppIdIm,Livelog,getUserSig }from 'api'
const { ipcRenderer } = window.require?window.require("electron"):'';
const {TRTCParams, TRTCRoleType, TRTCAppScene,TRTCVideoStreamType,TRTCAudioQuality,TRTCRenderParams, TRTCVideoRotation,
    TRTCVideoFillMode, TRTCVideoMirrorType, Rect, TRTCScreenCaptureProperty, TRTCVideoEncParam,
    TRTCVideoResolution, TRTCVideoResolutionMode,
} = window.require?window.require('trtc-electron-sdk'):''
// const TRTCCloud = window.require?window.require('trtc-electron-sdk').default:''

const SharePop = () => {
    const [search] = useSearchParams()
    const [rtcCloud,setRtcCloud] = useState(global.rtcCloud)//new TRTCCloud()
    const [personList,setPersonList] =useState([])
    const [imList,setImList] =useState([])
    const [group,setGroup] =useState([])
    const [isShow,setIsShow] =useState(true)
    var [userId,setUserId] =useState('yulan')
    const [userSig,setUserSig] = useState()
   
      
    useEffect(()=>{ 
            function onError(errCode, errMsg) {
            // errorCode 可参考 https://cloud.tencent.com/document/product/647/32257#.E9.94.99.E8.AF.AF.E7.A0.81.E8.A1.A8
            console.log(errCode, errMsg);
          }
          function onWarning(warningCode, warningMsg) {
            // warningCode 可参考 https://cloud.tencent.com/document/product/647/32257#.E8.AD.A6.E5.91.8A.E7.A0.81.E8.A1.A8
            console.log(warningCode, warningMsg);
          }
          
          function onEnterRoom(warningCode, warningMsg) {
            // warningCode 可参考 https://cloud.tencent.com/document/product/647/32257#.E8.AD.A6.E5.91.8A.E7.A0.81.E8.A1.A8
            console.log(warningCode, warningMsg);
          }
          function onUserVideoAvailable(userId, available) {
            if (available === 1) {
                const cameraView = document.querySelector('.video_dom');
                rtcCloud.startRemoteView(userId,cameraView,TRTCVideoStreamType.TRTCVideoStreamTypeBig);
                const param = new TRTCRenderParams(
                    TRTCVideoRotation.TRTCVideoRotation0,
                    TRTCVideoFillMode.TRTCVideoFillMode_Fill,
                    TRTCVideoMirrorType.TRTCVideoMirrorType_Enable
                );
                rtcCloud.setRemoteRenderParams(userId,TRTCVideoStreamType.TRTCVideoStreamTypeBig, param);
            } else {
                rtcCloud.stopRemoteView(userId, TRTCVideoStreamType.TRTCVideoStreamTypeBig);
            }
          }
          function onUserAudioAvailable(userId, available) {
            rtcCloud.muteRemoteAudio(userId, true);
            // if (available === 1) {
            //   openMicUserList.push(userId);
            // } else {
            //   openMicUserList = openMicUserList.filter((item) => item !== userId);
            // }
          }
          
        rtcCloud.setDefaultStreamRecvMode(false,true)
        rtcCloud.on('onError', onError);
        rtcCloud.on('onWarning', onWarning);
        rtcCloud.on('onEnterRoom', onEnterRoom);
        rtcCloud.on('onUserVideoAvailable', onUserVideoAvailable);
        rtcCloud.on('onUserAudioAvailable', onUserAudioAvailable);
        ipcRenderer.on("share", (e, arg) => {
            console.log('LIVEdetail00',arg)
            if(arg.key==='startSig') {
                getUserSig({uid:userId}).then(res=>{
                    if(res.code===200) {
                        const param1 = new TRTCParams();
                        param1.sdkAppId = sdkAppIdIm;
                        param1.userId = userId;  
                        param1.roomId = arg.roomId;
                        param1.userSig = res.data.user_sig;
                        param1.role = TRTCRoleType.TRTCRoleAudience;
                        rtcCloud.enterRoom(param1, TRTCAppScene.TRTCAppSceneLIVE);
                        setUserSig(res.data.user_sig)
                       
                    }
                })
            }
        })
    },[])
    useEffect(()=>{
        ipcRenderer.on("share", (e, arg) => {
            console.log('LIVEdetail00',arg)
            if(arg.key==='isVideo') {
                // if(arg.isVideo) {
                //     const cameraView = document.querySelector('.video_dom');
                //     rtcCloud.startRemoteView(userId,cameraView,TRTCVideoStreamType.TRTCVideoStreamTypeBig);
                //     const param = new TRTCRenderParams(
                //         TRTCVideoRotation.TRTCVideoRotation0,
                //         TRTCVideoFillMode.TRTCVideoFillMode_Fill,
                //         TRTCVideoMirrorType.TRTCVideoMirrorType_Enable
                //     );
                //     rtcCloud.setRemoteRenderParams(userId,TRTCVideoStreamType.TRTCVideoStreamTypeBig, param);
                   
                // }else{
                //     rtcCloud.stopRemoteView(userId, TRTCVideoStreamType.TRTCVideoStreamTypeBig);
                // }
            }else if(arg.key==='isIm') {

            }else if(arg.key==='isPerson') {
                
            }else if(arg.key==='personList'){
                setPersonList(arg.list)
            }
            else if(arg.key==='group'){
                setGroup(arg.group)
            }else if(arg.key==='imList') {
                setImList(arg.list)
            }
            
        })
    },[])
    // if(arg.isVideo&&arg.isShare) {
    //     setTimeout(()=>{
    //         const param = new TRTCRenderParams(
    //             TRTCVideoRotation.TRTCVideoRotation0,
    //             TRTCVideoFillMode.TRTCVideoFillMode_Fit,
    //             TRTCVideoMirrorType.TRTCVideoMirrorType_Auto
    //         );
    //         rtcCloud.setLocalRenderParams(param);
    //         rtcCloud.startLocalPreview(cameraView);
    //     },700)
    // }else{
    //     rtcCloud.stopLocalPreview(cameraView);
    // }
   function setGroupMemberMuteTime(id,stu,a) {
        ipcRenderer.send('share',{key:'MuteTime',id,stu,a});
   }
   function sendImMsg(text) {
        ipcRenderer.send('share',{key:'send',text});
    }
    function handlePackUp(stu) {
        setIsShow(stu)
        ipcRenderer.send('share_set',{key:'IsShow',isShow:stu});
    }
    return(
        <div className='share_pop'>
            {
                search.get('source')==='talk'?
                <div style={{width:'100%',height:'100%'}} >
                    <div className='talk' style={{display:isShow?'block':'none'}}>
                        <div className='header' >
                            <img alt='' src={require('images/talk_fff.png')} style={{width:'16px',height:'16px',marginRight:'16px'}}/>
                            <p style={{appRegion: "drag"}}>正在讲话：</p>
                            <img alt='' src={require('images/cebian_shou.png')} style={{width:'16px',height:'16px'}} onClick={()=>handlePackUp(false)}/>
                        </div>
                        <div className='video_dom'></div>
                    </div>
                    <div className='open_video' style={{display:!isShow?'flex':'none'}} onClick={()=>handlePackUp(true)}>
                        <img alt='' src={require('images/cebian_open.png')} style={{width:'16px',height:'16px'}} />
                    </div>
                </div>
                :
                search.get('source')==='person'?
                <div className='person'>
                    <Person stu={1} list={personList} group={group} setGroupMemberMuteTime={setGroupMemberMuteTime}/>
                </div>
                :
                search.get('source')==='im'?
                <div className='im'>
                    <Im stu={1} imList={imList} sendImMsg={sendImMsg}/>
                </div>:''
            }
            
        </div>
    )
}
export default SharePop