import './index.scss'
import { useState,useEffect } from 'react';
import Logo from 'components/logo'
import { Checkbox } from 'antd';
import { useNavigate } from 'react-router-dom';
import  Cookies from 'js-cookie'
import { getLogin,getUserInfo } from 'api'
import { Toast } from 'antd-mobile'
const {ipcRenderer} = window.require?window.require("electron"):'';
const Login = () =>{
    const [checked,setChecked] = useState(true)
    const go = useNavigate()
    const [inputType,setInputType] = useState('password')
    function handleTo() {

    }
    // useEffect(()=>{
    //     handleGetLogin()
    // },[])
    const [mobile,setMobile] = useState(window.location.origin=='http://localhost:3000'?'18513000003':'')
    const [password,setPassword] = useState(window.location.origin=='http://localhost:3000'?'123456':'')
    function handleGetLogin() {
        if(!checked) {
            Toast.show({
                icon: 'fail',
                content: '请阅读相关协议',
              })
            return
        }
        if(!mobile) {
            Toast.show({
                icon: 'fail',
                content: '请输入手机号',
              })
            return
        }
        if(!password) {
            Toast.show({
                icon: 'fail',
                content: '请输入密码',
              })
            return
        }
        getLogin({
            mobile:mobile,
            password:password,
        }).then(res=>{
            console.log(res)
            if(res.code==200) {
                Cookies.set('token',res.data.token)
                handleUserinfo()
            }else{
                Toast.show({
                    icon: 'fail',
                    content: res.msg,
                  })
            }
        })
    }
    function handleUserinfo() {
        getUserInfo({}).then(res=>{
            if(res.code==200) {
                Cookies.set('userinfo',JSON.stringify(res.data))
                go('/home',{replace:true})
            }
        })
    }
    function navigate(t) {
        ipcRenderer.send('openUrl',{url:t})
    }
    return(
        <div className="login">
            <Logo />
            <div className='form_item mt30'>
                <img className='img1' src={require('images/phone.png')} alt="" />
                <input type="text" value={mobile} onInput={(e)=>setMobile(e.target.value)} maxLength="11" placeholder='请输入手机号'/>
            </div>
            <div className='form_item mt13'>
                <img className='img1' src={require('images/pwd.png')} alt="" />
                <input type={inputType} value={password} onInput={(e)=>setPassword(e.target.value)} placeholder='请输入密码'/>
                <img className='img2 cursor' onClick={()=>{setInputType(inputType==='text'?'password':'text')}} src={require('images/'+(inputType==='password'?'input_hide':'input_show')+'.png')} alt="" />
            </div>
            <div className='forget cursor' onClick={()=>{navigate('/forget')}}>忘记密码？</div>
            <div className={mobile&&password&&checked?'jkb_btn login_btn mt30 cursor':'login_btn mt30 cursor'} onClick={()=>{handleGetLogin()}}>
                登录
            </div>
            <div className='agrent'>
                <Checkbox checked={checked} onChange={()=>{setChecked(!checked)}}>
                    <p className='agrent_p'> 已阅读并同意<span onClick={()=>{handleTo()}}>《隐私政策》</span>跟<span onClick={()=>{handleTo()}}>《用户协议》</span></p>
                </Checkbox>
            </div>
        </div>
    )
}
export default Login