import './index.scss'
// import TRTC from 'trtc-js-sdk';

import {sdkAppId} from 'api'
import { useEffect,useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import DeviceDetector from 'rtc-device-detector-react';
import 'rtc-device-detector-react/dist/index.css';
import { Modal,message } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import Im from'./im'
import Person from './person'
import TIM from 'tim-js-sdk';
import { sdkAppIdIm,Livelog,getUserSig }from 'api'
import TIMProfanityFilterPlugin from 'tim-profanity-filter-plugin';
import  Cookies from 'js-cookie'
const { confirm } = Modal;
const { ipcRenderer } = window.require?window.require("electron"):'';
// const TRTCCloud = window.require?window.require('trtc-electron-sdk').default:''
const {TRTCParams, TRTCRoleType, TRTCAppScene,TRTCVideoStreamType,TRTCAudioQuality,TRTCRenderParams, TRTCVideoRotation,
    TRTCVideoFillMode, TRTCVideoMirrorType, Rect, TRTCScreenCaptureProperty, TRTCVideoEncParam,
    TRTCVideoResolution, TRTCVideoResolutionMode
} = window.require?window.require('trtc-electron-sdk'):''
var stupop1 = false
// 18513000003
const Live = () =>{
    console.log(JSON.parse(Cookies.get('userinfo')))
    const [visible, setVisible] = useState(true);
    const [networkDetectInfo, setNetworkDetectInfo] = useState({});
    // var go = useNavigate()
    const { pathname,search } = useLocation()
    const [query] = useSearchParams()
    var [isVideo,setIsVideo] =useState(false)
    var [isAudio,setIsAudio] =useState(false)
    var [isScreen,setIsScreen] =useState(false)
    var [isStart,setIsStart] =useState(false)
    var [isMsg,setIsMsg] =useState(true)
    var [userId,setUserId] =useState(Cookies.get('userinfo')?JSON.parse(Cookies.get('userinfo')).id+'':{})
    var [userInfo] =useState(Cookies.get('userinfo')?JSON.parse(Cookies.get('userinfo')):{})
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [screenList,setScreenList] = useState([])
    const [rtcCloud,setRtcCloud] = useState(global.rtcCloud)//new TRTCCloud()
    const [selectWin,setSelectWin] =useState(0)
    const [isShare,setIsShare] =useState(false)
    const [isPerson,setIsPerson] =useState(false)
    const [tim,setTim] = useState()
    const [groupID,setGroupID] = useState(query.get('id'))
    const [liveStu,setLiveStu] = useState(query.get('status'))
    const [userSig,setUserSig] = useState()
    const [personList,setPersonList] = useState([])
    const [group,setGroup] = useState([])
    const [imList,setImList] = useState([])
    const [imList1,setImList1] = useState([])

    useEffect(()=>{
        getUserSig({uid:userId}).then(res=>{
            if(res.code===200) {
                setUserSig(res.data.user_sig)
            }
        })
    },[userId])
    useEffect(()=>{
       if(!userSig) {
        return
       }
       setTim(TIM.create({SDKAppID:sdkAppIdIm}))
    },[userSig])
    function onExitRoom(reason) {
        if(!stupop1) {
            message.error('因直播违规被下架')
            stupop1 = true
        }
        console.log(reason)

        setTimeout(()=>{
            getLivelog(2)
            rtcCloud.exitRoom();
            setIsStart(false)
            tim.dismissGroup(groupID);
            go(-1,pathname+search)
            stupop1 = false
        },1500)
    }
    rtcCloud.on('onExitRoom', onExitRoom);

    function getGroupMemberList() {
        tim.getGroupMemberList({
            groupID:groupID,
            count:100,
            offset:0
        }).then(function(imResponse) {
            setPersonList(imResponse.data.memberList)
            ipcRenderer.send('share',{key:'personList',list:imResponse.data.memberList});
            console.log('群成员列表',imResponse.data.memberList); // 群成员列表
        }).catch(function(imError) {
            console.warn('getGroupMemberList error:', imError);
        });
    }
    function sendImMsg(text,stu=2) {
        console.log('text',tim)
        if(text==='') {
            message.error('发送消息不能为空')
            return
        }
        let message1 = tim.createTextMessage({
            to: groupID,
            conversationType: TIM.TYPES.CONV_GROUP,
            payload: {
              text: text
            },
          });
          // 2. 发送消息
        tim.sendMessage(message1).then(function(imResponse) {
            // 发送成功
            if(stu===2) {
                var arr = JSON.parse(JSON.stringify(imList))
                arr.push(imResponse.data.message)
                console.log('监听消息1',arr)
                setImList(arr)
                ipcRenderer.send('share',{key:'imList',list:arr});
                console.log('监听消息',imList)
            }else{
                var arr1= JSON.parse(JSON.stringify(imList1))
                arr1.push(imResponse.data.message)
                setImList1(arr1)
            }

        }).catch(function(imError) {
            // 发送失败
            console.warn('sendMessage error:', imError);
        });
    }
    useEffect(()=>{
        var arr = [...imList,...imList1]
        setImList(arr)
        ipcRenderer.send('share',{key:'imList',list:arr});
        console.log('监听消息1',imList)
    },[imList1])
    useEffect(()=>{
        console.log('tim1',tim)
        if(!tim) {
            return
        }
        tim.setLogLevel(0);
        tim.registerPlugin({'tim-profanity-filter-plugin': TIMProfanityFilterPlugin});
        tim.on(TIM.EVENT.SDK_READY, onSdkReady);
        tim.on(TIM.EVENT.MESSAGE_RECEIVED, onMessageReceived);
        function onMessageReceived(event) {
            var arr = JSON.parse(JSON.stringify(imList1))
            event.data.forEach((message) => {
                if (message.type === TIM.TYPES.MSG_TEXT) {
                    arr.push(message)
                    console.log('监听消息',arr)
                  // 文本消息 - https://web.sdk.qcloud.com/im/doc/zh-cn/Message.html#.TextPayload
                }
            })
            setImList1(arr)
        }

        function onSdkReady(event) {
            tim.updateMyProfile({
                nick: userInfo.username,
                avatar: userInfo.avatar,
                gender: userInfo.sex==1?TIM.TYPES.GENDER_MALE:TIM.TYPES.GENDER_FEMALE,
                // selfSignature: '我的个性签名',
            });
            //子窗口消息监听
            ipcRenderer.on("share", (e, arg) => {
                console.log('text',arg)
                if(arg.key=='MuteTime') {
                    setGroupMemberMuteTime(arg.id,arg.stu,arg.a)
                }else if(arg.key=='send') {
                    sendImMsg(arg.text,1)
                }
            })
            if(liveStu==3) {
                setTimeout(()=>{
                    getMessageList()

                },2000)

                tim.getGroupProfile({ groupID: groupID}).then(function(imResponse) {
                  getGroupMemberList()

                  setInterval(()=>{
                      getGroupMemberList()
                  },5000)
                  setGroup(imResponse.data.group)
                  ipcRenderer.send('share',{key:'group',group:imResponse.data.group});
                  console.log('创建的群的资料',imResponse.data.group);
                }).catch(function(imError) {
                  console.warn('getGroupProfile error:', imError); // 获取群详细资料失败的相关信息
                });
                return
            }
            tim.createGroup({
                name:'技考帮',
                groupID:groupID,
                type:TIM.TYPES.GRP_MEETING,
            }).then(function(imResponse) {
                getGroupMemberList()

                setInterval(()=>{
                    getGroupMemberList()
                },5000)
                setGroup(imResponse.data.group)
                ipcRenderer.send('share',{key:'group',group:imResponse.data.group});
                console.log('创建的群的资料',imResponse.data.group);
            }).catch(function(imError) {
                console.warn('createGroup error:', imError); // 创建群组失败的相关信息
            });

        };

            tim.login({
                userID:userId,
                userSig:userSig,
            }).then(function(imResponse) {

                if (imResponse.data.repeatLogin === true) {
                    // 标识帐号已登录，本次登录操作为重复登录。v2.5.1 起支持
                    console.log(imResponse.data.errorInfo);
                }
            }).catch(function(imError) {
                console.warn('login error:', imError); // 登录失败的相关信息
            });;
            if(liveStu!=3) {
                return()=>{
                    tim.logout();
                    tim.dismissGroup(groupID);
                }
            }
    },[tim])
    function getMessageList(id){
        tim.getMessageList({
            conversationID:'GROUP'+(id || groupID),
          }).then(function(imResponse1) {
            const { messageList, isCompleted, nextReqMessageID } = imResponse1.data;
            console.log('messageList',messageList)
            var arr = JSON.parse(JSON.stringify(imList))
            messageList.map((v,i)=>{
                if(v.type!='TIMCustomElem') {
                    arr.push(v)
                }
            })
            console.log('监听消息1',arr)
            setImList(arr)
            ipcRenderer.send('share',{key:'imList',list:arr});
            if(!isCompleted) {
                getMessageList(nextReqMessageID)
            }
            // messageList - 消息列表
            // isCompleted - 拉取完成标识， true 已拉完，false 未拉完，可以通过 nextMessageSeq 的返回值进行续拉
            // nextMessageSeq - 续拉起始 sequence，当 isCompleted 返回 true 时，nextMessageSeq 返回空字符串
          });
    }
    function setGroupMemberMuteTime(id,stu,a=1) {
        if(a==1) {
            tim.setGroupMemberMuteTime({
                groupID: groupID,
                userID: id,
                muteTime: !stu?600:0 // 禁言10分钟；设为0，则表示取消禁言
            }).then(function(imResponse) {
                console.log(imResponse.data.group); // 修改后的群资料
                console.log(imResponse.data.member); // 修改后的群成员资料
                getGroupMemberList()
            }).catch(function(imError) {
                console.warn('setGroupMemberMuteTime error:', imError); // 禁言失败的相关信息
            });
        }else{
            tim.updateGroupProfile({
                groupID: groupID,
                muteAllMembers: stu, // true 表示全体禁言，false表示取消全体禁言
            }).then(function(imResponse) {
                console.log(imResponse.data.group) // 修改成功后的群组详细资料
                setGroup(imResponse.data.group)
                ipcRenderer.send('share',{key:'group',group:imResponse.data.group});
            }).catch(function(imError) {
                console.warn('updateGroupProfile error:', imError); // 修改群组资料失败的相关信息
            });
        }
    }
    rtcCloud.on('onError', onError);
    rtcCloud.on('onWarning', onWarning);
    rtcCloud.on('onEnterRoom', (result) => {
        console.log(222,result);
        // onEnterRoom 参见 https://web.sdk.qcloud.com/trtc/electron/doc/zh-cn/trtc_electron_sdk/TRTCCallback.html#event:onEnterRoom
        if (result > 0) {

          console.log('Enter room succeed');

        } else {
          // 参见进房错误码 https://cloud.tencent.com/document/product/647/32257#.E8.BF.9B.E6.88.BF.E7.9B.B8.E5.85.B3.E9.94.99.E8.AF.AF.E7.A0.81
          console.log('Enter room failed');
        }
    });
    function onError(errCode, errMsg) {
        // errorCode 可参考 https://cloud.tencent.com/document/product/647/32257#.E9.94.99.E8.AF.AF.E7.A0.81.E8.A1.A8
        console.log(errCode, errMsg);
    }
    function onWarning(warningCode, warningMsg) {
        // warningCode 可参考 https://cloud.tencent.com/document/product/647/32257#.E8.AD.A6.E5.91.8A.E7.A0.81.E8.A1.A8
        console.log(warningCode, warningMsg);
    }
    useEffect(()=>{
        const networkDetectInfo = {
            sdkAppId: sdkAppId,
            roomId: parseInt(groupID),
            uplinkUserInfo: {
              uplinkUserId: 'uplink_test',
              uplinkUserSig: 'eJwtzNsKgkAUheF3mdvC9ozHEboyUKKCyCIjiGBG23lg0EmF6N0z7XJ9C-43iTcHo5U18QkzgMzHjUJWGlMc*aUKrPKblo3*343I70qhID61AFzP4SafHtkrrOXgtm0zAJhUY-kzx2WcU-DcfwWzoX7edxbLd0VUe73ZZmFCw*110ZVJDGqlH7MTjYJ0-QwuR5EsyecLCdE0OA__',
            },
            downlinkUserInfo: {
              downlinkUserId: 'downlink_test',
              downlinkUserSig: 'eJwtzEELgjAcBfDvsnPYf8u5JnTqouQl9BIEIWzpv9kaOkyJvnumHt-vPd6HFFke9LolMWEBkM2cUWnr8Y4zq9fbNmjNzevOr4NOmdI5VCSmIYDYR3Inl0YPDls9OeecAcCiHp9-iwSTklII1xespv*ovm5PqSiOdCzsI0sa4RKel9LQfGRt1afnMmsEH5KLNAfy-QGD*TTM',
            },
          }
          setNetworkDetectInfo(networkDetectInfo)
    },[])

    useEffect(()=>{
        ipcRenderer.send('share',{key:'isVideo',isVideo:isVideo,isShare:isShare,groupID:groupID});
    },[isShare,isVideo])
    useEffect(()=>{
        ipcRenderer.on("share", (e, arg) => {
            if(arg.key==='isVideo') {
                const cameraView = document.querySelector('.local_stream');
                console.log('LIVEdetail',arg)
                if(arg.isShare) {
                    rtcCloud.stopLocalPreview(cameraView);
                }
                if(arg.isVideo) {
                    setTimeout(()=>{
                        const param = new TRTCRenderParams(
                            TRTCVideoRotation.TRTCVideoRotation0,
                            TRTCVideoFillMode.TRTCVideoFillMode_Fit,
                            TRTCVideoMirrorType.TRTCVideoMirrorType_Auto
                        );
                        rtcCloud.setLocalRenderParams(param);
                        rtcCloud.startLocalPreview(cameraView);
                    },400)

                }else{
                    rtcCloud.stopLocalPreview(cameraView);
                }
            }
        })
    },[])
    function handleIm (key) {
        ipcRenderer.send('share',{key:key});
    }
    function handleIsVideo() {
        setIsVideo(!isVideo)
    }
    function handleIsAudio () {
        if(isAudio) {
            rtcCloud.stopLocalAudio(TRTCAudioQuality.TRTCAudioQualitySpeech)
        }else{
            rtcCloud.startLocalAudio(TRTCAudioQuality.TRTCAudioQualitySpeech);
        }
        setIsAudio(!isAudio)
    }
    function handleWin () {
        if(!isStart) {
            message.error('请先开启直播！')
            return
        }
        if(isScreen) {
            rtcCloud.stopScreenCapture()
            setIsScreen(false)
            setTimeout(()=>{
                //隐藏分享窗口
                setIsShare(false)
                ipcRenderer.send('IsHideWin',{url:pathname+search,isShare:false});
            },500)
        }else{

            message.loading({content:'正在获取屏幕数据...',duration:0})
            setTimeout(()=>{
                showModal()
            },1000)
             // https://web.sdk.qcloud.com/trtc/electron/doc/zh-cn/trtc_electron_sdk/TRTCCloud.html#getScreenCaptureSources

        }
    }
      const showModal = () => {
        message.destroy()
        var list = rtcCloud.getScreenCaptureSources(160, 90, 32, 32)
        setScreenList(list)
        setIsModalOpen(true);
      };

      const handleOk = () => {
        // https://web.sdk.qcloud.com/trtc/electron/doc/zh-cn/trtc_electron_sdk/Rect.html
            const captureRect = new Rect(10, 10, 10, 10);
            // https://web.sdk.qcloud.com/trtc/electron/doc/zh-cn/trtc_electron_sdk/TRTCScreenCaptureProperty.html
            const property = new TRTCScreenCaptureProperty(
                true, true, true, 0, 0, false
            );
            console.log(screenList)
            if (screenList.length > 0) {
                rtcCloud.selectScreenCaptureTarget(screenList[selectWin], captureRect, property)
            }
            const screenshareDom = document.querySelector('.local_stream');
            // https://web.sdk.qcloud.com/trtc/electron/doc/zh-cn/trtc_electron_sdk/TRTCVideoEncParam.html
            const encParam = new TRTCVideoEncParam(
                TRTCVideoResolution.TRTCVideoResolution_1920_1080,
                TRTCVideoResolutionMode.TRTCVideoResolutionModeLandscape,
                15,
                2000,
                0,
                false
            );
            rtcCloud.startScreenCapture(screenshareDom, TRTCVideoStreamType.TRTCVideoStreamTypeSub, encParam);
            // rtcCloud.startLocalAudio(TRTCAudioQuality.TRTCAudioQualityDefault);
            // rtcCloud.startSystemAudioLoopback();
            setIsModalOpen(false);
            setIsScreen(true)

            setTimeout(()=>{
                //隐藏分享窗口
                ipcRenderer.send('IsHideWin',{url:pathname+search,isShare:true});
                setIsShare(true)
            },300)

      };

      const handleCancel = () => {
        setIsModalOpen(false);
      };

    function handleStartOrEnd(stu=2) {
        if(!userSig) {
            message.info('加载中请稍后！')
            return
        }
        ipcRenderer.send('home', {key:'add'})
        if(isStart) {
            confirm({
                title: stu==3?'暂停直播':'停止直播',
                icon: <ExclamationCircleFilled />,
                content: '正在开播，确定停播吗？',
                okText: '确定',
                cancelText:"取消",
                onOk() {
                    getLivelog(stu)
                    rtcCloud.exitRoom();
                    setIsStart(false)
                    if(stu!=3) {
                        tim.dismissGroup(groupID);
                    }
                    go(-1,pathname+search)
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }else{
            var param = new TRTCParams();
            param.sdkAppId = sdkAppId;
            param.userId = userId;
            param.roomId = parseInt(groupID);
            param.userSig = userSig
            param.role = TRTCRoleType.TRTCRoleAnchor;
            rtcCloud.enterRoom(param, TRTCAppScene.TRTCAppSceneLIVE);
            getLivelog(1)
            setIsStart(true)
            ipcRenderer.send('share',{key:'startSig',roomId:parseInt(groupID)});
        }
    }
    function getLivelog(s) {
        Livelog({
            live_id:groupID,
            live_status:s
        })
    }
    function maximizeWin() {
        ipcRenderer.send('max',pathname+search); // 通知主进程我要进行窗口最小化操作
    }
    function minimizeWin() {
        ipcRenderer.send('min',pathname+search); // 通知主进程我要进行窗口最小化操作
    }
    function closeWin() {
        getLivelog(2)
        rtcCloud.exitRoom();
        setIsStart(false)
        if(liveStu!=3) {
            tim.dismissGroup(groupID);
        }
        ipcRenderer.send('close',pathname+search); // 通知主进程我要关闭
    }
    function go(t,u='') {
        console.log({url:t,u:u})
        ipcRenderer.send('openUrl',{url:t,u:u})
    }
    function handleSelectWin(i) {
        setSelectWin(i)
    }
    function handleIsPerson() {
        setIsMsg(false)
        setIsPerson(!isPerson)
    }
    function handleIsMsg() {
        setIsPerson(false)
        setIsMsg(!isMsg)

    }
    return (
        <div>{!isShare?
        <div className='live'>
             <div className='header11' style={{appRegion: "drag"}}></div>
            <div className='header'>
                <div className='left'>
                    <img src={require('images/logo2.png')} alt="" />
                </div>
                <div className='right'>
                    <div className='photo'>
                        <img src={userInfo.avatar} alt="" />
                    </div>
                    <i></i>
                    <div className='small' onClick={()=>minimizeWin()}>
                        <img src={require('images/small.png')} alt="" />
                    </div>
                    <div className='big' onClick={()=>maximizeWin()}>
                        <img src={require('images/big.png')} alt="" />
                    </div>
                    <div className='close' onClick={()=>closeWin()}>
                        <img src={require('images/close.png')} alt="" />
                    </div>
                </div>
            </div>
            <div className='content'>
                <div className='left'>
                    <div className='top'>
                        <div className='photo'>
                            <img src={userInfo.avatar} alt="" />
                        </div>
                        <h2>{userInfo.username}的直播间</h2>
                        <div className='image'>
                            <img src={personList[0]&&personList[0].avatar || require('images/MR_img.png')} alt="" />
                        </div>
                        <div className='image'>
                            <img src={personList[1]&&personList[1].avatar ||require('images/MR_img.png')} alt="" />
                        </div>
                        <div className='image'>
                            <img src={personList[2]&&personList[2].avatar ||require('images/MR_img.png')} alt="" />
                        </div>
                        <p>{personList.length}人在看</p>
                    </div>
                    <div className='center local_stream' id='local_stream'></div>
                    <div className='bottom'>
                        <div className='talk_v'>
                            <div className='item' onClick={()=>handleIsAudio()}>
                                <img src={require('images/'+(isAudio?'':'no_')+'talk.png')} alt="" />
                                <p>{isAudio?'静音':'解除静音'}</p>
                            </div>
                            <div className='item' style={{marginLeft:'40px'}} onClick={()=>handleIsVideo()}>
                                <img src={require('images/'+(isVideo?'':'no_')+'video.png')} alt="" />
                                <p>{isVideo?'停止视频':'开启视频'}</p>
                            </div>
                        </div>
                        <div className='talk_v'>
                            <div className='item' onClick={()=>handleWin()}>
                                <img src={require('images/'+(isScreen?'no_':'')+'win.png')} alt="" />
                                <p>{isScreen?'结束共享':'共享屏幕'}</p>
                            </div>
                            <div className='item' style={{marginLeft:'40px'}} onClick={()=>handleIsMsg()}>
                                <img src={require('images/msg.png')} alt="" />
                                <p>聊天</p>
                            </div>
                            <div className='item' style={{marginLeft:'40px'}} onClick={()=>handleIsPerson()}>
                                <img src={require('images/more_peo.png')} alt="" />
                                <p>管理成员</p>
                            </div>
                        </div>
                        <div style={{display:'flex'}}>
                            <div className='talk_btn cursor' style={{background:'red'}} onClick={()=>handleStartOrEnd()}>{isStart?'停止直播':'开始直播'}</div>
                            {isStart?<div className='talk_btn cursor' style={{background:'#ccc',width:"100px",marginLeft:"10px"}} onClick={()=>handleStartOrEnd(3)}>暂停直播</div>:''}
                        </div>

                    </div>
                </div>
                {/* onClick={()=>handleStartOrEnd()} */}
                {isMsg?<Im imList={imList} sendImMsg={sendImMsg}/>:""}
                {isPerson?<Person list={personList} group={group} setGroupMemberMuteTime={setGroupMemberMuteTime}/>:''}
            </div>
            <DeviceDetector
            visible={visible}
            onClose={() => setVisible(false)}
            hasNetworkDetect={true}
            networkDetectInfo={networkDetectInfo}></DeviceDetector>
            <Modal width="52vw" cancelText="取消" okText="确认分享" style={{height:'60vh'}} title="选择要分享的屏幕或应用窗口" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <div className='share_win'>
                    <h3>屏幕</h3>
                    <div className='list_win'>
                    {screenList.map((v,i)=>{
                    return(v.type===1?
                        <div className={selectWin==i?'item_win active':'item_win'} onClick={()=>handleSelectWin(i)}>
                            <canvas
                                className="preview-canvas"
                                width={v.thumbBGRA?.width}
                                height={v.thumbBGRA?.height}
                                ref={(ref) => {
                                    if (ref === null) return;
                                    if (v.thumbBGRA?.width && v.thumbBGRA?.height) {
                                    const ctx = ref.getContext('2d');
                                    const img= new ImageData(
                                        new Uint8ClampedArray(v.thumbBGRA?.buffer),
                                        v.thumbBGRA?.width,
                                        v.thumbBGRA?.height
                                    );
                                    if (ctx != null) {
                                        ctx.putImageData(img, 0, 0);
                                    }
                                    }
                                }}
                                data-id={v.sourceId}
                                />
                            <p className='win_p'>{v.sourceName}</p>
                        </div>
                    :'')
                    })}
                    </div>
                    <h3>窗口</h3>
                    <div className='list_win'>
                    {screenList.map((v,i)=>{
                        return(v.type!==1?
                            <div className={selectWin==i?'item_win active':'item_win'} onClick={()=>handleSelectWin(i)}>
                            <canvas
                            className="preview-canvas"
                            width={v.thumbBGRA?.width}
                            height={v.thumbBGRA?.height}
                            ref={(ref) => {
                                if (ref === null) return;
                                if (v.thumbBGRA?.width && v.thumbBGRA?.height) {
                                const ctx = ref.getContext('2d');
                                const img= new ImageData(
                                    new Uint8ClampedArray(v.thumbBGRA?.buffer),
                                    v.thumbBGRA?.width,
                                    v.thumbBGRA?.height
                                );
                                if (ctx != null) {
                                    ctx.putImageData(img, 0, 0);
                                }
                                }
                            }}
                            data-id={v.sourceId}
                            />
                             <p className='win_p'>{v.sourceName}</p>
                        </div>
                        :'')
                        }
                    )}
                    </div>
                </div>
            </Modal>
        </div>:

            <div className='bottom btn_list_v'>
                 <div className='center local_stream' id='local_stream' style={{display:'none',opacity:0,width:0,height:0,overflow:'hidden'}}></div>
                <div className='talk_v'>
                    <div className='item' onClick={()=>handleIsAudio()}>
                        <img src={require('images/'+(isAudio?'':'no_')+'talk.png')} alt="" />
                        <p>{isAudio?'静音':'解除静音'}</p>
                    </div>
                    <div className='item'  onClick={()=>handleIsVideo()}>
                        <img src={require('images/'+(isVideo?'':'no_')+'video.png')} alt="" />
                        <p>{isVideo?'停止视频':'开启视频'}</p>
                    </div>
                    <div className='item' onClick={()=>handleWin()}>
                        <img src={require('images/'+(isScreen?'no_':'')+'win.png')} alt="" />
                        <p>{isScreen?'结束共享':'共享屏幕'}</p>
                    </div>
                    <div className='item' onClick={()=>handleIm('isIm')}>
                        <img src={require('images/msg.png')} alt="" />
                        <p>聊天</p>
                    </div>
                    <div className='item' onClick={()=>handleIm('isPerson')}>
                        <img src={require('images/more_peo.png')} alt="" />
                        <p>管理成员</p>
                    </div>
                </div>
                <div className='talk_btn cursor' onClick={()=>handleWin()}>{isScreen?'结束共享':'共享屏幕'}</div>
                {/* <div className='talk_btn cursor' onClick={()=>handleStartOrEnd()}>{isStart?'停止直播':'开始直播'}</div> */}
            </div>}
        </div>
    )
}
export default Live
