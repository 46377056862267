import { POST,GET,POST_JSON,FILE } from "server"

export const getLogin=(data)=>{
    return POST('/index.php/mapi/teachar/login', data)
} 
export const addLiveRecord=(data)=>{
    return POST('/index.php/mapi/live/add_live_record', data)
} 

export const getLiveRecordList=(data)=>{
    return POST('/index.php/mapi/live/get_live_record_list', data)
} 
export const insertLiveRecordLog=(data)=>{
    return POST('/index.php/mapi/live/insert_live_record_log', data)
} 
//忘记密码
export const getForget=(data)=>{
    return GET('/index.php/mapi/login/forget_password', data)
} 
export const userFeedback=(data)=>{
    return POST('/index.php/mapi/user/user_feedback', data)
} 
export const getUserInfo=(data)=>{
    return GET('/index.php/mapi/user/get_user_info', data)
} 
export const updateUserInfo=(data)=>{
    return POST('/index.php/mapi/user/update_user_info', data)
} 
export const UploadImg=(data)=>{
    return FILE('/index.php/mapi/app/uploadFile', data)
} 
export const getConfig=(data)=>{
    return GET('/index.php/mapi/app/config', data)
} 
// 消息
export const messageList=(data)=>{
    return POST('/index.php/mapi/message/message_list', data)
} 
// 删除消息
export const deleteMessage=(data)=>{
    return POST('/index.php/mapi/message/delete_message', data)
} 
// 消息详情
export const getMessageDetail=(data)=>{
    return POST('/index.php/mapi/message/get_message_detail', data)
} 
export const GetCode=(data)=>{
    return POST('/mapi/login/get_code', data)
} 
//直播记录
export const Livelog=(data)=>{
    return POST('/index.php/mapi/live/insert_live_record_log', data)
} 
export const getUserSig=(data)=>{
    return POST('/mapi/tencent_trtc/get_user_sig', data)
} 
//历史直播
export const getHistoryLiveList=(data)=>{
    return POST('/index.php/mapi/live/get_history_live_list', data)
} 
//删除历史直播
export const teacherDeleteLive=(data)=>{
    return POST('/index.php/mapi/live/teacher_delete_live', data)
} 
//删除历史直播
export const getLiveRecordDetail=(data)=>{
    return POST('/index.php/mapi/live/get_live_record_detail', data)
} 


export const sdkAppId = 1400784726
export const sdkAppIdIm = 1400784726