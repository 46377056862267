import { useEffect, useState } from 'react'
import './index.scss'
const Person =(props) =>{
    const [keyword,setkeyword] = useState('')
    const [list,setlist] = useState(props.list)
    useEffect(()=>{
        handleSearch()
    },[props.list])
    function handleSearch() {
        if(keyword==='') {
            setlist(props.list)
            return
        }
        var ss = list.filter((p) => {
            return p.nick.indexOf(keyword) > -1;
        });
        console.log(keyword,ss)
        setlist(ss)
    }
    return(<div className='right_im' style={props.stu==1?{marginLeft:0,width:'auto',height:'100vh'}:{}}>
    <div className='person_top'>
        <div className='top top_p'  style={props.stu==1?{appRegion: "drag"}:{}}>
            <img src={require('images/more_peo.png')} alt="" />
            <p>管理成员({props.list.length})</p>
        </div>
        <div className='person_search'>
            <input type='text' value={keyword} onInput={(e)=>setkeyword(e.target.value)}></input> 
            <img src={require('images/search.png')} className="cursor" onClick={()=>handleSearch()}/>
        </div>
    </div>
    <div className='center' style={{padding:'0 20px'}}>
        <div className='person_list'>
            {list.map((v,i)=>{
            return(
                <div className='person_item'>
                    <div className='left1'>
                       <div className='img'>
                            <img src={v.avatar}/>
                       </div>
                        <div className='name'>
                            <h2>{v.nick}</h2>
                            {v.role=='Owner'?<h3>(讲师，我)</h3>:''}
                        </div>
                    </div>
                    {console.log(v.muteUntil * 1000  > Date.now())}
                    {v.role!=='Owner'?<div className='right1 cursor' onClick={()=>{props.setGroupMemberMuteTime(v.userID,v.muteUntil!=0&&v.muteUntil * 1000  > Date.now())}}>
                        <img src={require('images/'+(v.muteUntil!=0&&v.muteUntil * 1000  > Date.now() || props.group.muteAllMembers?'no_':'')+'msg.png')}/>
                    </div>:''}
                </div>
            )})}
        </div>
    </div>
    <div className='bottom'>
       <div className='person_btn'> 
            <div className='person_btn_item cursor' onClick={()=>{props.setGroupMemberMuteTime('',true,2)}}>全体禁言</div>
            <div className='person_btn_item cursor' onClick={()=>{props.setGroupMemberMuteTime('',false,2)}} style={{marginLeft:'7px'}} >解除全体禁言</div>
       </div>
    </div>
</div>)
}
export default Person