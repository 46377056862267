import './index.scss'
import React from'react'
import { getHistoryLiveList,teacherDeleteLive } from 'api'
import { Dialog,Toast } from 'antd-mobile'
const {ipcRenderer} = window.require?window.require("electron"):'';

const History = () =>{
    const [list,setList] = React.useState([])
    const [page,setPage] = React.useState(1)
    const myRef = React.useRef()
    const [loding,setloding] = React.useState(1)
    const [flag,setflag] = React.useState(true)
    React.useEffect(()=>{
        handleMessageList()
    },[])
    function handleMessageList() {
        getHistoryLiveList({page:page}).then(res=>{
            if(res.code===200) {
                setflag(true)
                if(res.data&&res.data.length!==0) {
                    var arr = list 
                    arr = [...arr,...dataResort(res.data),]
                    let page1 = page
                    ++page1
                    setPage(page1)
                    setList(arr)
                    if(res.data.length<10) {
                        setloding(2)
                    }
                    console.log(arr)
                }else{
                    setloding(2)
                }
            }
        })
    }
     function dataResort(arr) {
        var day = [
            {id:0,name:'周日'},
            {id:1,name:'周一'},
            {id:2,name:'周二'},
            {id:3,name:'周三'},
            {id:4,name:'周四'},
            {id:5,name:'周五'},
            {id:6,name:'周六'},
        ]
        let newArr = [];
        arr.forEach((item, i) => {
            item.start_time1 = item.start_time.split(' ')[0]
            if(list.length!==0&&item.start_time1===list[list.length-1].start_time1){
                var array = list
                array[array.length-1].subList = array[array.length-1].subList.concat(item);
                setList(array)
                return
            }
           
            // 默认当前操作的数据下标 -1 
            let index = -1;
            // 判断数组中是否已经存在当前遍历数据的时间
            let isExists = newArr.some((newItem, j) => {
                if (item.start_time1 === newItem.start_time1) {
                    // 存在就保存当前数据下标  用于插入数据
                    index = j;
                    return true;
                }
            })
            // 如果没有就存储一条新对象数据
            if (!isExists) {
                let num = new Date(item.start_time).getDay()
               
                var day1 =''
                day.map((v,i)=>{
                    if(v.id===num) {
                        day1 =  v.name
                    }
                })
                newArr.push({
                    start_time1: item.start_time1,
                    day:day1,
                    mounth:new Date(item.start_time1).getMonth()+1,
                    date:new Date(item.start_time1).getDate(),
                    year:new Date(item.start_time1).getFullYear(),
                    subList: [item]
                })
            } else {
                // 如果有就插入到已存在的对象中
                newArr[index].subList.push(item);
            }
        })      
        return newArr
      }
    const Scroll = () => {
        let height1 = myRef.current.clientHeight  // 可以得到我们设置的高度 (不包括滚动的高度)
        let height2 = myRef.current.scrollTop  // 当滚动时被卷去的高度
        let height3 = myRef.current.scrollHeight  // 全部的高度 包括滚动的高度
        if(height1 + height2 + 5 > height3 && flag){   // 当距离底部20 则会触发
            // 加了flag 是为了防止反复触发
            setflag(false)
            handleMessageList()
            setloding(1)
        }
    }
    function go(t) {
        ipcRenderer.send('openUrl',{url:t})
    }
    function handleDel(e,val,i,idx) {
        e.stopPropagation()
        if(val.del_status===0) {
            Toast.show({
                content: '删除审核中',
              })
            return
        }
        Dialog.confirm({
            content: '是否删除历史直播',
            onConfirm: async () => {
                teacherDeleteLive({live_id:val.id}).then(res=>{
                    if(res.code==200) {
                        Toast.show({
                            icon: 'success',
                            content:  res.msg,
                          })
                        var arr = JSON.parse(JSON.stringify(list)) 
                        arr[i].subList[idx].del_status=0
                        setList(arr)
                    }else{
                        Toast.show({
                            content: res.msg,
                        })
                    }
                })
              
            },
          })
        
    }
    return(
        <div className="history">
             <div className="title">历史直播</div>
             <div className='home_list' ref={myRef} onScroll={Scroll}>
             {
                list.map((v,i)=>{
                    return(
                        <div>
                    <div className='time'>
                        <p className='left'>{v.mounth}月{v.date}日 {v.day}</p>
                        <p className='right'>{v.year}年</p>
                    </div>
                    {v.subList.map((val,idx)=>{
                        return(
                        <div className='home_item cursor' onClick={()=>go('/history/detail?url='+val.record_video_url+'&id='+val.id)}>
                            <div className='home_item_top'>
                                <div className='img'>
                                    <img src={val.live_cover} alt="" />
                                </div>
                                <div className='info'>
                                    <p className='p1'>{val.live_name}</p>
                                    <p className='p2'>{val.live_username}</p>
                                    <p className='p3'>{val.live_desc}</p>
                                    <p className='p4'>开始时间：{val.start_time}</p>
                                </div>
                            </div>
                            <div className='home_item_bot'>
                                <p>详情</p>
                                <p onClick={(e)=>handleDel(e,val,i,idx)}>删除{val.del_status==0?'(审核中)':''}</p>
                            </div>
                        </div>
                       
                        )
                    })
                    }
                     </div>
                )
            })
        }
                <div className='loding' >{loding===1?'正在加载...':loding===2?'没有更多数据了':''}</div>
            </div>
        </div>
    )
}
export default History