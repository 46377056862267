import './index.scss'
import { useNavigate } from 'react-router-dom'
import  Cookies from 'js-cookie'
import { useState } from 'react'
const {ipcRenderer} = window.require?window.require("electron"):'';
const My = () =>{
    const navigate = useNavigate()
    const [userInfo] = useState(Cookies.get('userinfo')?JSON.parse(Cookies.get('userinfo')):{})
    function handleLogout() {
        ipcRenderer.send('home', {key:'logout'})
    }
    function go(t) {
        ipcRenderer.send('openUrl',{url:t})
    }
    return (
        <div className="my">
            <div className='my_header cursor' onClick={()=>go('/userinfo')}>
                <div className='left'>
                    <div className='img'>
                        <img src={userInfo.avatar||require('images/MR_img.png')} alt="" />
                    </div>
                    <p>{userInfo.username}</p>
                </div>
                <div className='right'>
                    <img src={require('images/item_right.png')} alt="" />
                </div>
            </div>
            <div className='my_list cursor'>
                <div className='my_list_item' onClick={()=>go('/forget?stu=change')}>
                    <p>修改密码</p>
                    <img src={require('images/my_right.png')} alt="" />
                </div>
                <div className='my_list_item' onClick={()=>go('/contact')}>
                    <p>联系我们</p>
                    <img src={require('images/my_right.png')} alt="" />
                </div>
                <div className='my_list_item' onClick={()=>go('/feedback')}>
                    <p>意见反馈</p>
                    <img src={require('images/my_right.png')} alt="" />
                </div>
            </div>
            <div className='my_btn cursor' onClick={()=>handleLogout()}>
                退出登录
            </div>
        </div>
    )
}
export default My