import './index.scss'
import { Toast } from 'antd-mobile'
import React from 'react'
import { userFeedback } from 'api'
import { useNavigate } from 'react-router'
const { ipcRenderer } = window.require?window.require("electron"):'';
const Feedback = () =>{
    const [text,setText] = React.useState()
    const go = useNavigate()
    function handleFeedback() {
        if(!text) {
            Toast.show({
                icon: 'fail',
                content: '请输入反馈内容',
              })
            return
        }
        userFeedback({content:text}).then(res=>{
            if(res.code==200) {
                Toast.show({
                    icon: 'success',
                    content: '提交成功',
                  })
                setText('')
                go(-1)
            }else{
                Toast.show({
                    icon: 'fail',
                    content: res.msg,
                  })
            }
        })
    }
    function navigate(t) {
        ipcRenderer.send('openUrl',{url:t})
    }
    return(
        <div className="feedback">
            <div className='title'>意见反馈</div>
            <div className='content'>
                <h3>请描述您遇到的问题</h3>
                <textarea value={text} onInput={(e)=>setText(e.target.value)} placeholder='请描述您遇到的问题'></textarea>
            </div>
            <div className='btn cursor' onClick={()=>{handleFeedback()}}>
                提交意见
            </div>  
        </div>
    )
}
export default Feedback