import './index.scss'
import { getMessageDetail }from'api'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
const NewsDetail = () =>{
    const [detail,setDetail] = useState({})
    const [search] = useSearchParams()
    useEffect(()=>{
        function handleGetMessageDetail() {
            getMessageDetail({message_id:search.get('id')}).then(res=>{
                if(res.code===200) {
                    res.data.content = formatRichText(res.data.content)
                    setDetail(res.data)
                }
            })
        }
        handleGetMessageDetail()
    },[search])
   
    /**
 * 处理富文本里自带的图片大小，添加自定义的图片大小
 * 1.去掉img标签里的style、width、height属性
 * 2.img标签添加style属性：width:100%;height:auto
 * 3.修改所有style里的width属性为max-width:100%
 * 4.去掉<br/>标签
 */
    function formatRichText(notice) {
        let newContent = notice.toString().replace(/<img[^>]*>/gi, function(match, capture) {
            // match = match.replace(/style="[^"]+"/gi, '').replace(/style=\"(.*)\"/gi, '');
            //重点改了这里，直接删掉了存在的style，无论是在前还是后的style
            match = match.toString().replace(/style="[^"]+"/gi, '').replace(
                /style\s*?=\s*?([‘"])[\s\S]*?\1/ig, '');
            match = match.toString().replace(/width="[^"]+"/gi, '').replace(/width='[^']+'/gi, '');
            match = match.toString().replace(/height="[^"]+"/gi, '').replace(/height='[^']+'/gi, '');
            return match;
        });
        newContent = newContent.toString().replace(/style="[^"]+"/gi, function(match, capture) {
            match = match.toString().replace(/width:[^;]+;/gi, 'width:100%;').replace(/width:[^;]+;/gi,
                'width:100%;');
            return match;
        });
        newContent = newContent.toString().replace(/<br[^>]*\/>/gi, '');
        newContent = newContent.toString().replace(/\<img/gi, '<img style="width:100%;height:auto;"');
        return newContent;
    }
    return(
        <div className="news">
            <div className="title">消息详情</div>
            <div className='detail'>
                <h1>{detail.title}</h1>
                <p dangerouslySetInnerHTML={{__html:detail.content}}></p>
            </div>
           
        </div>
    )
}
export default NewsDetail